<template>
  <div class="content">
    <div class="topTitle">桔多多用户注册及服务协议</div>
    <div class="topTexrt">
      本协议是桔多多用户（以下简称“您”）与桔多多网站（www.juzifenqi.com，简称“本站”）所有者及其关联公司（以下统称为“桔多多”或“我们”）之间就桔多多服务等相关事宜所订立的契约。
    </div>
    <div class="main">
      <div class="mainTilte">第1条 协议的确认及相关定义</div>
      <div class="mainPart"><p> 1.1请您仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有疑问，请勿进行下一步操作。</p>
        您可通过桔多多的在线客服或官方热线400-1071-666进行咨询，以便我们为您解释和说明。<p> 您通过页面点击或其他方式确认即表示您已同意本协议</p></div>
      <div class="mainPart"> 1.2您同意本协议即视为您确认自己具有享受桔多多服务相应的权利能力和行为能力，能够独立承担法律责任。</div>
      <div class="mainPart"> 1.3您确认，如果您在18周岁以下，您只能在父母或其他监护人的监护参与下才能使用桔多多服务。</div>
      <div class="mainPart"> 1.4桔多多保留在中华人民共和国大陆地区施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</div>
      <div class="mainPart"><p>
        1.5您在桔多多平台使用具体服务或功能时，请您遵守相关的协议、须知和规则(如有)，且这些服务或功能可能由不同主体提供。您在使用之前请仔细阅读相关协议、须知和规则内容，如您对前述内容有疑问，请勿进行后续操作，您使用前述服务或功能，即表示您同意相关的协议、须知和规则。我们尊重并保护您的隐私，在您使用我们的各项服务时，相关服务提供方将按照
        <a href="https://mall.juzifenqi.com/agreement/protocol_PrivacyProtection.html">《桔多多隐私保护政策》</a> 严格保护您的个人信息。</p>
      </div>
      <div class="mainPart"><p> 1.6桔多多：</p>
        指北京桔子分期电子商务有限公司及其关联公司，例如：桔多多网站、桔多多客户端及桔多多商城微信公众号、桔子特卖商城微信公众号的运营方北京桔子分期电子商务有限公司；桔子分期商城微信公众号、桔子商城微信公众号的运营方大连桔多多科技有限公司；逾期资产管理服务的提供方辽宁友信资产管理有限公司、辽宁友信电子网络科技有限公司、盖州市友信网络科技有限公司、营口市站前区友信数字网络科技有限公司、吉林友信网络科技有限公司、朝阳友信网络科技有限公司及后续可能设立的逾期资产管理主体。“关联公司”是指一方现在或者将来控制、受控制或者与其出于共同控制下的任何公司、合法机构以及上述公司、合法机构的合法继承人。
      </div>
      <div class="mainPart"><p> 1.7桔多多平台：</p>指已经开通的及后续可能开通的提供桔多多服务的网络平台（网站、客户端、微信公众号等）及其服务体系。</div>
      <div class="mainPart"><p> 1.8桔多多账户：</p>
        是指您注册通过后获得的代表您本人身份的用于登录桔多多平台、使用桔多多服务的账户。对于部分需要身份验证或认证的服务，如贷款撮合服务等，如您未完成指定的身份验证、认证，则不能使用相关服务。
      </div>
      <div class="mainPart"><p /></div>
    </div>
    <div class="main">
      <div class="mainTilte">第2条 本站服务</div>
      <div class="mainPart">2.1桔多多通过互联网依法为您提供互联网信息等服务，您在完全同意本协议及本站相关规定的情况下，方有权使用本站的相关服务。</div>
      <div class="maminPart">
        <p>2.2您必须自行准备如下设备和承担如下开支：</p>
        <span>（1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；</span><br>
        <span>（2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。</span>
      </div>
    </div>
    <div class="main">
      <div class="mainTilte">第3条 用户信息收集及保护</div>
      <div class="mainPart">
        3.1您应自行诚信向桔多多提供注册资料，您保证提供的注册资料真实、准确、完整、合法有效，您的注册资料如有变动的，应及时更新其注册资料。如果您提供的注册资料不合法、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且桔多多保留单方终止您使用桔多多各项服务的权利。
      </div>
      <div class="mainPart">
        3.2您在桔多多平台进行注册、浏览、下单、评价、参加活动等行为时，涉及您真实姓名、通信地址、联系电话、电子邮箱、订单详情、评价或反馈、投诉内容、cookies等信息的，我们有权从完成交易、提供配送、售后及客户服务、开展活动、完成良好的客户体验等多种角度予以收集，并将对其中涉及个人隐私信息予以严格保密，除非得到您的授权、为履行强行性法律义务（如国家安全机关指令）或法律另有规定、本协议或其他条款另有约定外，我们不会向外披露您的隐私信息。
      </div>
      <div class="mainPart">
        3.3您注册成功后，将产生用户名和密码等账户信息，您可以根据桔多多平台的规则更改您的密码。您应谨慎合理的保存、使用您的账户信息。您若发现任何非法使用您的账户或其他存在安全漏洞的情况的，请立即通知我们并向公安机关报案。
      </div>
      <div class="mainPart">
        3.4您同意桔多多拥有通过邮件、短信、电话、网站通知或声明等形式，向在桔多多平台注册、购物的用户、收货人发送订单信息、促销活动、售后服务、客户服务等告知信息的权利。如您不希望接收上述信息，可退订。
      </div>
      <div class="mainPart"><p>
        3.5您同意选择向您自己提供的商品销售商或服务提供商，或我们推荐或指派的商品销售商或服务提供商（以下统称为“销售商”）提交订单购买商品或服务的，视为您向销售商披露个人相关信息，接受销售商向您提供商品销售、配送、售后服务、客户服务、处理信用卡付款、数据分析、市场营销帮助或其他必要事宜；如前述全部或部分事宜之一涉及由销售商外的第三方履行的，销售商有权将您的信息以必要方式向第三方披露，第三方有权以履行上述事宜为目的联系您。</p>
      </div>
      <div class="mainPart">3.6您不得将在桔多多平台注册获得的账号、密码等账户信息提供给他人使用，否则您应承担由此产生的全部责任，并与实际使用人承担连带责任。</div>
      <div class="mainPart">3.7您同意桔多多有权使用您的注册信息、用户名、密码等信息，登陆进入您的注册账户，进行证据保全，包括但不限于公证、见证、协助司法机关进行调查取证等。</div>
      <div class="mainPart"><p>
        3.8您同意，为了确定您的真实身份，评价您的信用状况，确认您的订约、履约意愿，识别、控制交易中的风险，桔多多有权在您使用桔多多服务的期间向中国人民银行等机构审批通过设立的征信机构（以下简称“征信机构”）查询您的信用及相关信息。当您使用桔多多服务但对桔多多或桔多多的其他用户产生违约情形时，桔多多可以向上述征信机构查询能够联系到您的信息，包括但不限于电话号码、电子邮件地址、地址、邮编等，用于追究违约责任。为了查询这些信息的需要，桔多多可以将您的姓名、公民身份号码提交给前述征信机构用以确定被查询其信息的信息主体。</p>
      </div>
      <div class="mainPart">3.9您同意，桔多多可以将您在使用桔多多服务期间产生的相关信息提供给征信机构。</div>
    </div>
    <div class="main">
      <div class="mainTilte">第4条 用户依法言行义务</div>
      <p>本协议依据国家相关法律法规规章制定，您同意严格遵守以下义务</p>
      <div class="mainPart">
        4.1不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
      </div>
      <div class="mainPart">4.2从中国大陆向境外传输资料信息时必须符合中国有关法律法规；</div>
      <div class="mainPart">4.3不得利用桔多多平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</div>
      <div class="mainPart">4.4不得干扰桔多多平台的正常运作，不得侵入桔多多平台及国家计算机信息系统；</div>
      <div class="mainPart">4.5不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</div>
      <div class="mainPart">4.6不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</div>
      <div class="mainPart">4.7不得教唆他人从事本条所禁止的行为；</div>
      <div class="mainPart">4.8除本协议、其他条款或另有其他约定外，您不得利用在桔多多平台注册的账户进行经营活动、牟利行为及其他未经我们许可的行为，包括但不限于以下行为：</div>
      <div class="mainPart">4.8.1恶意利用技术手段或其他方式，为获取优惠、折扣或其他利益而注册账户、下单等行为，影响其他用户正常消费融资行为或相关合法权益、影响桔多多正常经营秩序的行为；</div>
      <div class="mainPart">4.8.2发布广告、垃圾邮件；</div>
      <div class="mainPart">
        4.8.3任何对商品目录、说明、价格、数量、其他用户信息或其他内容的下载、转载、收集、衍生利用、复制、出售、转售或其他形式的使用，无论是否通过Robots、Spiders、自动仪器或手工操作；
      </div>
      <div class="mainPart">4.8.4桔多多平台相关规则、政策、或网页活动规则中限制、禁止的行为；</div>
      <div class="mainPart">4.8.5其他影响桔多多对用户账户正常管理秩序的行为。</div>
      <div class="mainPart">
        4.9您不得利用任何非法手段获取其他用户个人信息，不得将其他用户信息用于任何营利或非营利目的，不得泄露其他用户或权利人的个人隐私，否则桔多多有权采取本协议规定的合理措施制止您的上述行为，情节严重的，将提交公安机关进行刑事立案。
      </div>
      <div class="mainPart">
        4.10您不得发布任何侵犯他人著作权、商标权等知识产权或其他合法权利的内容；如果有其他用户或权利人发现您发布的信息涉嫌知识产权、或其他合法权益争议的，这些用户或权利人有权要求桔多多删除您发布的信息，或者采取其他必要措施予以制止，桔多多将会依法采取措施。
      </div>
      <div class="mainPart"><p>4.11您应不时关注并遵守桔多多平台不时公布或修改的各类规则规定。我们保有删除桔多多平台内各类不符合法律政策或不真实的信息内容且无须通知您的权利。</p></div>
      <div class="mainPart">
        4.12您不得对我们的系统和程序采取反向工程手段进行破解，不得对上述系统和程序（包括但不限于源程序、目标程序、技术文档、客户端至服务器端的数据、服务器数据）进行复制、修改、编译、整合或篡改，不得修改或增减我们系统的功能；您理解，我们的服务有赖于系统的准确运行及操作。若出现系统差错、故障、您或我们不当获利等情形，您同意我们可以采取更正差错、扣划款项等适当纠正措施。
      </div>
      <div class="mainPart"><p>
        4.13若您未遵守以上规定的，我们可对您发布的违法或侵权信息依法或依本协议进行删除或屏蔽；同时我们保留对可疑交易、非法交易、高风险交易或交易纠纷的独立判断和确定，有权依法对上述订单采取暂停交易、终止交易或对账户采取冻结、关闭措施并进行调查，同时采取向有关单位报告等必要处理措施或依有关单位合法指示行事，如因此导致我们或其他方受损的，您需要自行承担相关法律责任。</p>
      </div>
    </div>
    <div class="main">
      <div class="mainTilte">第5条 商品及服务</div>
      <div class="mainPart">
        5.1桔多多平台上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，由于网站上商品信息的数量庞大，虽然我们会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；桔多多欢迎纠错，并会视情况给予纠错者一定的奖励。
      </div>
      <div class="mainPart"><p>5.2桔多多平台并不是商品本身的销售商，</p>
        商品本身的销售商由您自行选择并向桔多多提供，或者由桔多多以商品展示的形式向您推荐（“推荐”并不意味着桔多多提供任何形式的担保），如果桔多多展示的商品未注明商品销售商名称的，实际向您发货的商家即为商品销售商。对于商品本身，您拥有作为消费者的一切合法权利，桔多多虽然同意会配合您向商品销售商行使消费者权利，但桔多多在此声明，您与商品销售商之间基于商品买卖的任何纠纷，包括但不限于：商品的寄送、质量、售后服务等纠纷，与桔多多没有任何法律上或合同上的关联。
      </div>
      <div class="mainPart">5.3我们有权随时调整本协议相关服务的费用标准。</div>
      <div class="mainPart">5.4您使用桔多多平台申请贷款的，我们将根据您的委托和授权，提供贷款撮合、信用评估、信息咨询、贷款机构推荐、信息对接、账户管理、逾期资产管理等服务。<p>
        请您理解，为了向您及您的贷款方负责，前述委托或授权一旦做出不可由您单方面取消或终止。</p></div>
      <div class="mainPart">
        5.5您使用桔多多平台申请贷款的，可享受我们提供的息费减免、商品优惠等相关优惠活动。您应及时足额履行还款义务，否则我们有权取消前述优惠并要求您返还相应金额的资金，我们亦有权通过您的还款账户或其它绑定的账户直接划扣前述资金。
      </div>
      <div class="mainPart">
        5.6您使用桔多多平台申请贷款的，如您提供虚假个人信息、未按期还款或您的还款账户存在解除绑定、挂失银行卡、冻结账户等致使无法划扣到期应还款项的情况的，贷款的相关债权人（如贷款人、贷款相关债权的受让人、追偿权人等，以下统称“债权人”）有权提前终止您的贷款合同，我们亦有权代表债权人通知并要求您偿还所有到期与未到期的全部款项。
      </div>
      <div class="mainPart">5.7您使用桔多多平台申请贷款的，我们可为您提供还款提醒服务，您不因我们未进行服务或未收到还款提醒而主张逾期免责。</div>
      <div class="mainPart">
        5.8您使用桔多多平台申请贷款的，我们可为您与债权人提供还款支持，您同意授权我们通过银行或三方支付机构根据债权人的交易指令从您绑定的专用还款账户代扣相应款项。如您发生逾期或已绑定的还款账户中金额不足或扣款失败，我们及债权人有权通过银行或第三方支付机构从您名下其他银行卡中代扣相应金额，无论该银行卡是否绑定为专用还款账户。
      </div>
      <div class="mainPart">
        5.9您使用桔多多平台申请贷款的，自逾期之日起，若未能联系上您，我们亦可以将您的相关贷款信息告知您提供的预留联系人进行通知转达。在您的相关联系人同意代偿的前提下，我们可以将相关贷款信息以及还款途径提供给代偿人。
      </div>
    </div>
    <div class="main">
      <div class="mainTilte">第6条 订单</div>
      <div class="mainPart">
        6.1在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。
      </div>
      <div class="mainPart">6.2尽管桔多多做出最大的努力，但商品目录里的一小部分商品可能会有定价错误。如果发现错误定价，将采取下列之一措施，且不视为违约行为：</div>
      <div class="mainPart">6.2.1如果某一商品的正确定价低于销售商的错误定价，销售商将按照较低的定价向您销售交付该商品。</div>
      <div class="mainPart">6.2.2如果某一商品的正确定价高于销售商的错误定价，销售商会通知您，并根据实际情况决定是否取消订单、停止发货、为已付款用户办理退款等。</div>
      <div class="mainPart"><p>
        6.3由于市场变化及各种以合理商业努力难以控制的因素的影响，我们无法保证您提交的订单信息中希望购买的商品都会有货；如您下单所购买的商品，发生缺货，您有权取消订单，销售商或我们亦有权取消订单，并为已付款的用户办理退款。</p>
      </div>
    </div>
    <div class="main">
      <div class="mainTilte">第7条 配送</div>
      <div class="mainPart">7.1销售商将会把商品送到您所指定的收货地址，所有在桔多多平台上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点等相关信息估计得出的。
      </div>
      <div class="mainPart">
        <p>7.2因如以下情况造成订单延迟或无法配送、交货等，桔多多不承担延迟配送、交货的责任：</p>
        <span>（1）您提供的信息错误、地址不详细等原因导致的；</span>
        <span>（2）货物送达后无人签收，导致无法配送或延迟配送的；</span>
        <span>（3）情势变更因素导致的；</span>
        <span>（4）因节假日、大型促销活动、店庆、预购或抢购人数众多等原因导致的；</span>
        <span>（5）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。</span>
      </div>
      <div class="mainPart">7.3
        您在桔多多平台购买的商品由第三方配送公司为您完成配送，配送公司提供的或通过其后台获取的物流流转记录与签收时间均为有效交货凭证；您购买的商品采用在线传输方式交付的，我们或第三方配送公司向您指定系统发送的时间为交付时间，发送记录为交付凭证；您购买服务的，生成的电子或者实物凭证中载明的时间为交付时间。
      </div>
    </div>
    <div class="main">
      <div class="mainTilte">第8条 个人征信信息查询</div>
      <div class="mainPart">8.1 您同意依法设立的征信机构向有关机构征集本人的信息，并出具《个人信用报告》。</div>
      <div class="mainPart"><p>8.2 您使用桔多多平台申请贷款的，我们可通过依法设立的征信机构查询您本人信息并获取您的《个人信用报告》，以了解您信用状况。</p></div>
      <div class="mainPart">8.3您同意桔多多向依法设立的征信机构提供您申请贷款所登记的您的信息等相关信息。</div>
      <div class="mainPart">8.4 您同意拥有您不良信息的机构，通过依法设立的征信机构向桔多多提供您的不良信息。您已被事先明确告知并已同意拥有您不良信息的机构提供您的不良信息。</div>
      <div class="mainPart">8.5您同意桔多多向依法设立的征信机构提供您本人在本次借贷申请中所登记的个人信息、相关借贷信息及后续还款记录等信息。</div>
      <div class="mainPart">8.6您同意桔多多向依法设立的征信机构提供您本人的贷款不良信息。您事先已明确被告知并同意桔多多向依法设立的征信机构提供您本人的不良信息。</div>
    </div>
    <div class="main">
      <div class="mainTilte">第9条 责任限制及不承诺担保</div>
      <div class="mainPart">9.1除非另有明确的书面说明,桔多多平台及其所包含的或以其他方式通过桔多多平台提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。
      </div>
      <div class="mainPart">
        9.2除非另有明确的书面说明,我们不对桔多多平台的运营及其包含在桔多多平台上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
      </div>
      <div class="mainPart">
        9.3桔多多不担保桔多多平台所包含的或以其他方式通过桔多多平台提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从桔多多平台发出的电子信件、信息没有病毒或其他有害成分。
      </div>
      <div class="mainPart">9.4如因不可抗力或其他无法控制的原因使我们的系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，桔多多会合理地尽力协助处理善后事宜。</div>
      <div class="mainPart">
        9.5您应对账户信息及密码承担保密责任，因您未能尽到信息安全和保密责任而致使您的账户发生任何问题的，您应承担全部责任。同时，因网络环境存在众多不可预知因素，因您自身终端网络原因（包括但不限于断网、黑客攻击、病毒等）造成您的桔多多账户或个人信息等被第三方窃取的，桔多多不承担赔偿责任。
      </div>
      <div class="mainPart">
        9.6您了解并同意，桔多多有权应国家有关机关的要求，向其提供您在桔多多的用户信息和交易记录等必要信息。如您涉嫌侵犯他人合法权益，则桔多多有权在初步判断涉嫌侵权行为可能存在的情况下，向权利人提供您必要的个人信息。
      </div>
      <div class="mainPart">9.7 我们可同时为您及您的（交易）对手方提供服务，您同意对我们的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张我们在提供服务时存在法律上的瑕疵。</div>
    </div>
    <div class="main">
      <div class="mainTilte">第10条 协议更新及用户关注义务</div>
      <div class="mainPart">
        <p>根据国家法律法规变化及运营需要，桔多多有权对本协议条款不时地进行修改，修改后的协议在本站公告满30天即生效，并代替原来的协议。</p>
        您可随时登陆查阅最新协议；您有义务不时关注并阅读最新版的协议、其他条款及网站公告。
        <p>如您不同意更新后的协议，可以且应立即停止接受桔多多服务；如您继续使用桔多多服务，即视为同意更新后的协议。</p>
        桔多多建议您在使用桔多多服务之前阅读本协议及本站的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
      </div>
    </div>
    <div class="main">
      <div class="mainTilte">第11条 法律管辖和适用</div>
      <div class="mainPart">
        本协议的订立、执行和解释及争议的解决均适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其他条款继续有效。
        <p>您与桔多多（详见本协议1.6条）之间包括但不限于本协议的一切财产权益纠纷，皆由原告住所地人民法院管辖。</p>
        为减轻各方诉讼成本，加快诉讼效率，各方同意争议之解决可采用视听传输技术等方式开庭。在争议处理期间，本协议不涉及争议的条款仍须履行。
      </div>
    </div>
    <div class="main" style="margin-bottom:10vh;">
      <div class="mainTilte">第12条 其他</div>
      <div class="mainPart">
        12.1您同意我们基于本协议相关服务之目的，向您发送有关通知或提醒。本协议相关服务的提供方以及纠纷解决机构（如审判机构、仲裁机构、调解机构、公证机构等）可采取邮寄、直接送达或电子送达（如传真、电子邮件、短信、微信、站内信、站内公告等）等方式向您发出通知及法律文书（如诉讼文书、支付令、判决书、裁定书、裁决书、调解书、公证书等）。
        <p>你同意接受电子送达，电子送达适用于所有通知及法律文书，并适用于本协议相关服务全流程及纠纷解决的全流程（诉前、诉中、执行，以及督促程序、仲裁、调解、公证等）。</p>
      </div>
      <div class="mainPart">
        <p>12.2您应确保您使用本协议相关服务时提供的联系信息（如通讯地址、单位地址、住址、传真号码、电子邮箱、手机号码、微信等）的准确和有效。</p>
        若您的前述信息发生变更，您应在变更后的3日内登录相关平台更新信息或以其它有效的书面方式通知我们。
        <p>您的前述联系信息均可作为有效的送达地址，在您做出变更通知前的送达均为有效送达。</p>
        如果因您提供的送达地址不准确、拒不提供送达地址、送达地址变更未书面告知等原因，致使相应的通知及法律文书无法送达或未及时送达，您将自行承担由此可能产生的法律后果。
      </div>
      <div class="mainPart">
        <p>
          12.3上述通知及法律文书通过邮寄方式送达的，邮件发出五日后即视为送达；直接送达的，送达人当场在送达回证上记明情况之日视为送达；通过传真、电子邮件、短信、微信、站内信送达的，发出即视为送达；通过站内公告方式送达的，一经公示即视为送达。 </p>
      </div>
      <div class="mainPart">
        <p>12.4本协议在签署、履行、终止过程中形成的任何电子合同、电子信息记录或数据，以及本协议相关服务的系统中提取出来的数据均具有法律效力，各方均认可其作为电子证据的有效性和真实性。</p>
      </div>
    </div>
    <div class="flexPart">
      <div class="toBack" @click="toback">返回用户授权</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agreement',
  methods: {
    toback() {
      window.history.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  text-align: left;

  .topTitle {
    width: 90%;
    height: auto;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bolder;
    line-height: 80px;
    text-align: center;
  }

  .topTexrt {
    width: 94%;
    height: auto;
    margin: 0 auto;
    font-size: 14px;
    font-weight: bolder;
    line-height: 20px;
    text-align: left;
    margin-bottom: 20px;
  }

  .main {
    width: 94%;
    height: auto;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;

    .mainTilte {
      font-weight: bolder;
      line-height: 26px;
      font-size: 16px;
    }

    .mainPart {
      line-height: 24px;

      p {
        font-weight: bolder;
        display: inline;

        a {
          color: #000;
          text-decoration: underline;
        }
      }

      span {
        display: block;
      }
    }
  }

  .flexPart {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 10vh;
    background-color: #eee;

    .toBack {
      background-color: #003ee7;
      width: 40vw;
      height: 5vh;
      border-radius: 10px;
      margin: 2.5vh auto;
      color: #fff;
      line-height: 5vh;
      text-align: center;
    }
  }

}
</style>
